<template>
  <div class="detail-page">
    <div class="detail-header">
      <img @click="back" src="@/assets/img/zhang/back.png" alt="" />
      <span>{{ title }}</span>
      <div class="reset" @click="backSpecialActions">取消</div>
      <div class="search" @click="saveSpecialActions('2')">提交并运行</div>
      <div class="searchTwo" @click="saveSpecialActions('1')">提交</div>
    </div>
    <div class="page">
      <Breadcrumb :routerList="routerList"></Breadcrumb>
      <el-card class="m-t-big" shadow="never">
        <!-- 标题栏 -->
        <TitleCard>
          <template v-slot:title>
            <Title>方案信息</Title>
          </template>
        </TitleCard>
        <div class="card-box">
          <!-- 行动名称 -->
          <el-row :gutter="20" class="card-row">
            <el-col :span="1.5">
              <span class="card-box-desc">
                <span class="symbol">*</span>
                行动名称
              </span>
            </el-col>
            <el-col :span="22">
              <el-input style="width: 47.5rem" v-model="actionsName" placeholder="请输入行动名称" maxlength="50" clearable></el-input>
            </el-col>
          </el-row>
          <!-- 平台类型 -->
          <el-row :gutter="20" class="card-row">
            <el-col :span="1.5">
              <span class="card-box-desc">
                <span class="symbol">*</span>
                平台类型
              </span>
            </el-col>
            <el-col :span="22" class="card-box-flex" style="
                display: flex;
                height: 3.75rem;
                box-sizing: border-box;
                padding-top: 1.25rem;
              ">
              <el-checkbox class="check-all" v-model="checkAllOne" @change="platformCheckAll" style="margin-right: 1.25rem">
                全选
              </el-checkbox>
              <el-checkbox-group class="checkbox-group" v-model="checkedPlatform" @change="platformChecked">
                <el-checkbox class="check-more" v-for="item in platformType" :label="item.value" :key="item.id" style="margin-bottom: 0.9375rem">
                  {{ item.label }}
                </el-checkbox>
              </el-checkbox-group>
            </el-col>
          </el-row>
          <!-- 主体类型 -->
          <el-row :gutter="20" class="card-row m-t-32">
            <el-col :span="1.5">
              <span class="card-box-desc">
                主体类型
              </span>
            </el-col>
            <el-col :span="22" class="card-box-flex" style="align-items: center;">
              <el-select v-model="organizationType" placeholder="请选择主体类型" value-key="value" style="width: 23.75rem" multiple clearable>
                <el-option v-for="item in organizationTypeList" :key="item.value" :label="item.label" :value="item"></el-option>
              </el-select>
              <div class="btn m-l-24" @click="addOrganizationType" style="float: none">
                添加
              </div>
            </el-col>
          </el-row>
          <!-- 主体类型tags -->
          <el-row :gutter="20" style="box-sizing: border-box; padding-left: 5.3125rem">
            <div class="tagsRegion">
              <div v-for="(val, index) in organizationTypeTags" :key="index" class="tagsRegion-card">
                <span>
                  {{ val.name }}
                </span>
                <img src="@/assets/img/wang/fill-del.png" @click="organizationTypeTags.splice(index, 1)" />
              </div>
            </div>
          </el-row>
          <!-- 开始时间 -->
          <el-row :gutter="20" class="card-row m-t-32">
            <el-col :span="1.5">
              <span class="card-box-desc">
                <span class="symbol">*</span>
                开始时间
              </span>
            </el-col>
            <el-col :span="22">
              <el-date-picker v-model="startTime" type="datetime" placeholder="请选择开始时间" style="width: 23.75rem"></el-date-picker>
            </el-col>
          </el-row>
        </div>
      </el-card>
      <el-card class="m-t-big" shadow="never">
        <!-- 标题栏 -->
        <TitleCard>
          <template v-slot:title>
            <Title>关键字信息</Title>
          </template>
        </TitleCard>
        <div class="card-box">
          <!-- 词组模式 -->
          <el-row :gutter="20" class="card-row m-b-16">
            <el-col :span="1.5">
              <span class="card-box-desc">
                关键词模式
              </span>
            </el-col>
            <el-col :span="16">
              <el-radio-group v-model="keywordType" @change="changeKeyWordType">
                <el-radio :label="1">模式1</el-radio>
                <el-radio :label="2">模式2</el-radio>
              </el-radio-group>
            </el-col>
          </el-row>
          <!-- 主关键词1 -->
          <template v-if="keywordType==1">
            <el-row :gutter="20" class="card-row">
              <el-col :span="1.5">
                <span class="card-box-desc">
                  <span class="symbol">*</span>
                  主关键词
                </span>
              </el-col>
              <el-col :span="22" class="card-box-flex">
                <el-alert class="card-box-alert" title="每组关键词内关键词是并的关系，关键词组与组之间是或的关系" type="warning" show-icon :closable="false"></el-alert>
                <div class="btn m-l" @click="addKeyWord">添加</div>
              </el-col>
            </el-row>
            <el-row :gutter="20" style="
              margin-top: 1.5625rem;
              box-sizing: border-box;
              padding-left: 6.25rem;
            " v-for="(item, index) in keyWordData" :key="index">
              <el-input v-model="item.keyWordInput" placeholder="请输入主关键词，回车添加" style="width: 35.75rem" @keyup.enter.native="addKeyWordList(index)"
                        clearable></el-input>
              <span style="
                color: #e60b1e;
                font-weight: 600;
                font-size: 1rem;
                margin-left: 1.25rem;
                cursor: pointer;
              " @click="removeInput(index)">
                移除
              </span>
              <div class="tagsRegion">
                <div v-for="(items, indexs) in item.keyWordTags" :key="indexs" class="tagsRegion-card">
                  <span>
                    {{ items.name }}
                  </span>
                  <img src="@/assets/img/wang/fill-del.png" @click="deldetkeyWord(index, indexs)" />
                </div>
              </div>
            </el-row>
          </template>
          <template v-if="keywordType==2">
            <div class="type2">
              <el-alert class="card-box-alert" title="每组关键词内关键词是并的关系，关键词组与组之间是或的关系" type="warning" show-icon :closable="false"></el-alert>
              <div class="box2-conent-li">
                <div class="key-words-1"><span>*</span>关键字词</div>
                <!-- <div class="key-words-2">
                  <el-input v-model="type2.keywordName" placeholder="词组名称,必填项" size="medium" clearable></el-input>
                </div> -->
                <div class="key-words-3">
                  <el-input v-model="type2.subjectKeywordName" placeholder="主关键词" size="medium" clearable></el-input>
                </div>
                <div class="key-words-4">
                  <el-input v-model="type2.viceKeywordName" placeholder="副关键词" size="medium" clearable></el-input>
                </div>
                <div class="key-words-5">
                  <el-input v-model="type2.secondaryKeywordName" placeholder="次关键词" size="medium" clearable></el-input>
                </div>
                <div class="key-words-6" @click="addKeyWordsList">添加</div>
              </div>
              <div class="box2-conent-li li3" v-for="(item,index) in type2.keyWordsList" :key="index">
                <div class="li3-left">
                  <!-- <el-input v-model="item.keywordName" placeholder="词组名称,必填项" size="medium" clearable></el-input> -->
                </div>
                <div class="li3-right">
                  <el-input v-model="item.subjectKeywordName" placeholder="主关键词" size="medium" clearable></el-input>
                  <el-input v-model="item.viceKeywordName" placeholder="副关键词" size="medium" clearable></el-input>
                  <el-input v-model="item.secondaryKeywordName" placeholder="次关键词" size="medium" clearable></el-input>
                </div>
                <div class="li3-text" @click="deleteKeyWordsList(index)">
                  删除
                </div>
              </div>
            </div>
          </template>
          <!-- 排除关键字 -->
          <el-row :gutter="20" class="card-row m-t-32">
            <el-col :span="1.5"><span class="card-box-desc">排除关键字</span></el-col>
            <el-col :span="22">
              <el-input style="width: 46.25rem" v-model="excludeKeywords" placeholder="请输入排除关键词" clearable></el-input>
            </el-col>
          </el-row>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import TitleCard from "@/components/TitleCard";
import Title from "@/base/Title";
import Breadcrumb from "@/components/breadcrumb.vue";
import Special from "@/api/special/index";
export default {
  components: {
    Breadcrumb,
    TitleCard,
    Title,
  },
  data() {
    return {
      keywordType: 1,//关键词模式
      type2: {//模式2关键字对象
        // keywordName: '',
        subjectKeywordName: '',
        viceKeywordName: '',
        secondaryKeywordName: '',
        keyWordsList: [],
      },
      keyWordData: [
        {
          keyWordInput: "",
          keyWordTags: [],
        },
      ], //初始化关键字数据
      title: "新增专项行动", //页面标题
      organizationTypeTags: [], //机构类型tags
      excludeKeywords: "", //排除关键字
      actionsName: "", //行动名称
      organizationType: "", //机构类型
      organizationTypeList: JSON.parse(localStorage.getItem("body_type")), //机构类型选项
      startTime: null, //开始时间
      checkAllOne: false, //平台类型全选
      checkedPlatform: [], //平台类型已选
      platformType: JSON.parse(localStorage.getItem("platform_type")), //平台类型选项
      isIndeterminateOne: false, //平台类型不确定状态
      routerList: ["专项行动", "新增专项行动"], //面包屑
      editId: null, //编辑数据id
    };
  },
  methods: {
    changeKeyWordType() {
      this.type2 = {
        subjectKeywordName: '',
        viceKeywordName: '',
        secondaryKeywordName: '',
        keyWordsList: [],
      }
      this.keyWordData = [
        {
          keyWordInput: "",
          keyWordTags: [],
        },
      ]
    },
    addKeyWordsList() {
      // this.type2.keywordName = this.type2.keywordName.trim();
      this.type2.subjectKeywordName = this.type2.subjectKeywordName.trim();
      this.type2.viceKeywordName = this.type2.viceKeywordName.trim();
      this.type2.secondaryKeywordName = this.type2.secondaryKeywordName.trim();
      if (this.type2.subjectKeywordName == "") {
        this.$message({
          message: "请填写完全后再添加",
          type: "warning"
        });
        return false
      }
      // if (this.type2.keywordName.length < 2 || this.subjectKeywordName.length < 2) {
      //   this.$message.warning('关键词字数必须大于等于2')
      //   return false
      // }
      this.type2.keyWordsList.push({
        keywordName: this.type2.keywordName,
        subjectKeywordName: this.type2.subjectKeywordName,
        viceKeywordName: this.type2.viceKeywordName,
        secondaryKeywordName: this.type2.secondaryKeywordName,
        // montiorType: "0" //检测类型  是客户端 因此是 0  0机构方案 1公共方案
      });
      this.type2.keywordName = ""; // 关键字
      this.type2.subjectKeywordName = ""; //主关键字
      this.type2.viceKeywordName = "";
      this.type2.secondaryKeywordName = ""; // 副关键字
    },
    deleteKeyWordsList(index) {
      this.type2.keyWordsList.splice(index, 1);
    },
    // 提交
    async saveSpecialActions(type) {
      let data = {};
      data.keywordType = this.keywordType
      if (this.actionsName != "") {
        data.name = this.actionsName;
      } else {
        this.$message.warning("请输入行动名称！");
        return false;
      }
      if (this.checkedPlatform && this.checkedPlatform.length > 0) {
        data.platformTypes = this.checkedPlatform.join(",");
      } else {
        this.$message.warning("请选择平台类型！");
        return false;
      }
      if (!this.startTime) {
        this.$message.warning("请选择开始时间！");
        return false;
      }
      if (this.keywordType == 1 && this.keyWordData && this.keyWordData[0].keyWordTags.length > 0) {
        let arrA = [];
        this.keyWordData.forEach((x, index) => {
          if (x.keyWordTags.length == 0) {
            this.keyWordData.splice(index, 1);
            return false;
          } else {
            let arrB = [];
            x.keyWordTags.forEach((y) => {
              arrB.push(y.name);
            });
            arrB = arrB.join(",");
            arrA.push(arrB);
          }
        });
        arrA = arrA.join("|");
        data.keyword = arrA;
      } else if (this.keywordType == 2) {
        // console.log(this.type2.keyWordsList,'哦');return
        let arr = this.type2.keyWordsList.map(item => {
          let keyWord = ''
          if (item.subjectKeywordName) {
            item.subjectKeywordName = item.subjectKeywordName.split(' ').join(',')
            keyWord = item.subjectKeywordName
          }
          if (item.subjectKeywordName&&item.viceKeywordName) {
            keyWord = keyWord +'|' + item.viceKeywordName.split(' ').join(',')
            
          }
          if (item.subjectKeywordName&&item.viceKeywordName&&item.secondaryKeywordName) {
            keyWord = keyWord + '|' + item.secondaryKeywordName.split(' ').join(',')
            console.log('提交是的词组',keyWord)
          }
          console.log('提交是的词组',keyWord)
          return keyWord //: item.subjectKeywordName + ',' + item.viceKeywordName + "," + item.secondaryKeywordName,//匹配关键字;组之间使用| 内使用, 依次为 主/副/次
        })
         data.keyword = arr.join("!");
      } else {
        this.$message.warning("请输入主关键词！");
        return false;
      }
      if (this.organizationTypeTags && this.organizationTypeTags.length > 0) {
        let arr = [];
        this.organizationTypeTags.forEach((x) => {
          arr.push(x.value);
        });
        data.bodyTypes = arr.join(",");
      } else {
        data.bodyTypes = "";
      }
      if (this.startTime) {
        data.startTime = this.$parent.formatDate(this.startTime);
      }
      if (this.excludeKeywords != "") {
        data.excludeKeyword = this.excludeKeywords;
      }
      if (this.title == "编辑专项行动") {
        type == "1" ? (data.status = 1) : (data.status = 2);
        data.id = this.editId / 1;
        const res = await Special.editSpecialActions(data);
        if (res.data.code == 200) {
          this.$message.success("修改成功！");
          this.$router.push("/special");
        }
      } else {
        type == "1" ? (data.status = 1) : (data.status = 2);
        const res = await Special.addSpecialActions(data);
        if (res.data.code == 200) {
          this.$message.success("新增成功！");
          this.$router.push("/special");
        }
      }
    },
    // 取消提交
    backSpecialActions() {
      this.$router.push("/special");
    },
    // 添加主关键字
    addKeyWordList(index) {
      let arr = this.keyWordData[index].keyWordInput.split(' ') //存储关键字的数组
      console.log(arr, 'sadsa');
      let reg = new RegExp(" ", "g");
      this.keyWordData[index].keyWordInput = this.keyWordData[index].keyWordInput.replace(reg, "");
      if (this.keyWordData[index].keyWordInput == "") {
        this.$message.warning("请输入主关键字！");
        return false;
      }
      arr.forEach((v, i) => {
        this.keyWordData[index].keyWordTags.push({
          name: v,
        });
      })
      this.keyWordData[index].keyWordTags = this.keyWordNoRepeat(
        this.keyWordData[index].keyWordTags
      );
      this.keyWordData[index].keyWordInput = "";
    },
    // 添加主关键字输入框
    addKeyWord() {
      let sum = 0;
      this.keyWordData.forEach((x) => {
        if (x.keyWordTags.length == 0) {
          sum += 1;
        }
      });
      if (sum == 0) {
        this.keyWordData.push({
          keyWordInput: "",
          keyWordTags: [],
        });
      }
    },
    // 移除关键字输入框
    removeInput(index) {
      if (this.keyWordData.length > 1) {
        this.keyWordData.splice(index, 1);
      }
    },
    // 关键词删除
    deldetkeyWord(index, indexs) {
      this.keyWordData[index].keyWordTags.splice(indexs, 1);
      if (this.keyWordData.length > 1) {
        if (this.keyWordData[index].keyWordTags.length == 0) {
          this.keyWordData.splice(index, 1);
        }
      }
    },
    // 添加机构类型
    addOrganizationType() {
      if (this.organizationType == "") {
        this.$message.warning("请选择主体类型！");
      } else {
        this.organizationType.forEach((item) => {
          let obj = {
            name: "",
            value: "",
          };
          obj.name = item.label;
          obj.value = item.value;
          this.organizationTypeTags.push(obj);
          this.organizationTypeTags = this.organizationTypeNoRepeat(this.organizationTypeTags);
        });
      }
      this.organizationType = "";
    },
    // 机构类型去重
    organizationTypeNoRepeat(arr) {
      const res = new Map();
      return arr.filter(
        (a) => !res.has(a.value) && res.set(a.value, 1) && !res.has(a.name) && res.set(a.name, 1)
      );
    },
    // 主关键字去重
    keyWordNoRepeat(arr) {
      const res = new Map();
      return arr.filter((a) => !res.has(a.name) && res.set(a.name, 1));
    },
    back() {
      this.$router.go(-1);
    },
    //全选平台类型
    platformCheckAll(val) {
      let checked = this.platformType.map((item) => {
        return item.value;
      });
      this.checkedPlatform = val ? checked : [];
      this.isIndeterminateOne = false;
    },
    //多选平台类型
    platformChecked(value) {
      let checkedCount = value.length;
      this.checkAllOne = checkedCount === this.platformType.length;
      this.isIndeterminateOne = checkedCount > 0 && checkedCount < this.platformType.length;
    },
  },
  created() {
    if (this.$route.query.info) {
      let params = JSON.parse(decodeURIComponent(this.$route.query.info));
      console.log(params.item,"关键字");
      if (params) {
        this.routerList = ["专项行动", "编辑专项行动"];
        this.keyWordData = [];
        this.title = params.title;
        this.editId = params.item.id;
        this.actionsName = params.item.name;
        this.checkedPlatform = params.item.platformTypes.split(",");
        if (this.checkedPlatform.length == this.platformType.length) {
          this.checkAllOne = true;
        }
        this.startTime = params.item.startTime;
        // 主体类型反显
        if ( params.item.accountBodyTypes) {
          params.item.accountBodyTypes.split(",").forEach((y) => {
            params.organizationTypeList.forEach((x) => {
              if (y == x.value) {
                let objC = {
                  name: "",
                  value: "",
                };
                objC.name = x.label;
                objC.value = x.value;
                this.organizationTypeTags.push(objC);
              }
            });
          });
        } else {
          this.organizationTypeTags = [];
        }
        // 关键字反显
        if(params.item.keywordType==1){//模式1
          if (params.item.keyWord.indexOf("|") != -1) {
          params.item.keyWord = params.item.keyWord.split("|");
          params.item.keyWord.forEach((x) => {
            let objA = {
              keyWordInput: "",
              keyWordTags: [],
            };
            x.split(",").forEach((y) => {
              let objB = {
                name: "",
              };
              objB.name = y;
              objA.keyWordTags.push(objB);
            });
            this.keyWordData.push(objA);
          });
        } else {
          let objA = {
            keyWordInput: "",
            keyWordTags: [],
          };
          params.item.keyWord.split(",").forEach((y) => {
            let objB = {
              name: "",
            };
            objB.name = y;
            objA.keyWordTags.push(objB);
          });
          this.keyWordData.push(objA);
        }
        }else{
          this.type2.keyWordsList = params.item.keyWord.split("!").map(item => {
            item = item.split("|")
          return {
            subjectKeywordName: item[0]?.split(',').join(' '), //主
            viceKeywordName: item[1]?.split(',').join(' '), //副
            secondaryKeywordName: item[2]?.split(',').join(' '), //次
          }
        })//关键词组
        }
        this.keywordType = params.item.keywordType*1
        // this.keywordType = 2
        this.excludeKeywords = params.item.excludeKeyword;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.detail-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  .detail-header {
    width: 100%;
    height: 56px;
    background-color: #ffffff;
    display: flex;
    justify-content: start;
    align-items: center;
    position: relative;
    .search {
      width: 120px;
      height: 36px;
      background: #fff1e8;
      border-radius: 4px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ed731f;
      cursor: pointer;
      position: absolute;
      right: 115px;
      text-align: center;
      line-height: 36px;
    }
    .searchTwo {
      width: 80px;
      height: 36px;
      background: #f58030;
      border-radius: 4px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      cursor: pointer;
      position: absolute;
      right: 25px;
      text-align: center;
      line-height: 36px;
    }
    .reset {
      width: 80px;
      height: 36px;
      background: #fff1e8;
      border-radius: 4px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ed731f;
      cursor: pointer;
      text-align: center;
      line-height: 36px;
      position: absolute;
      right: 245px;
    }
    img {
      width: 38px;
      height: 38px;
      margin: 9px 15px 9px 23px;
      cursor: pointer;
    }
    span {
      font-size: 20px;
      font-weight: 600;
      line-height: 56px;
      font-family: PingFangSC-Semibold, PingFang SC;
    }
  }
  .page {
    padding-top: 16px;
    .card-box {
      padding: 0 48px;
      .card-row {
        display: flex;
        align-items: center;
        .card-box-desc {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: bold;
          color: #333333;
          float: right;
          position: relative;
          .symbol {
            color: red;
            position: absolute;
            left: -10px;
          }
        }
        .card-box-flex {
          display: flex;
        }
        .card-box-alert {
          width: 572px;
          color: #ed731f;
          background: #fff5ee;
        }
      }
      .type2 {
        .card-box-alert {
          width: 572px;
          color: #ed731f;
          background: #fff5ee;
        }
        .box2-conent-li {
          display: flex;
          height: 36px;
          line-height: 36px;
          margin-top: 24px;
          .key-words-1 {
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 600;
            color: #333333;
            line-height: 36px;
            margin-right: 18px;
            span {
              display: inline-block;
              height: 36px;
              margin-right: 5px;
              color: red;
            }
          }
          .key-words-2 {
          }
          .key-words-3 {
          }
          .key-words-4 {
          }
          .key-words-5 {
          }
          .key-words-6 {
            margin-left: 16px;
            color: #267dff;
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            cursor: pointer;
          }
          div {
            .el-input {
              width: 150px;
              margin-right: 14px;
            }
          }
        }
        .li3 {
          position: relative;
          width: calc(100% - 800px);
          height: 204px;
          background: #f5f6fa;
          .li3-left {
            width: 20px;
            height: 36px;
            // margin: 84px 14px 84px 100px;
          }
          .li3-right {
            display: flex;
            flex-direction: column;
            .el-input {
              width: 572px;
              margin-top: 24px;
            }
          }
          .li3-text {
            position: absolute;
            left: 620px;
            top: 41%;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #e60b1e;
            cursor: pointer;
          }
        }
      }
    }
  }
}
.tagsRegion {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .tagsRegion-card {
    padding: 6px 16px;
    background: #f5f6fa;
    border-radius: 4px;
    font-size: 14px;
    color: #666666;
    display: flex;
    align-items: center;
    margin-right: 16px;
    margin-top: 16px;
    img {
      width: 16px;
      height: 16px;
      margin-left: 16px;
      cursor: pointer;
    }
  }
}
</style>
